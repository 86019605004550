@import '../styles/global.scss';

.projects {
  @include mobile {
    .gallery {
      ul {
        li {
          a {
            .card {
              height: 80px !important;
              width: 80px !important;
              img {
                height: 80px !important;
                width: 80px !important;
              }
            }
          }
        }
      }
    }
    .discography {
      ul {
        li {
          .card {
            height: 80px !important;
            width: 80px !important;
            img {
              height: 80px !important;
              width: 80px !important;
            }
          }
        }
      }
    }
  }
  @include landscape {
    flex-direction: row !important;
    .gallery {
      ul {
        width: auto !important;
      }
    }
  }
  .gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
      padding: 0;
      width: 80vw;

      li {
        list-style: none;
        a {
          text-decoration: none;

          .card {
            display: flex;
            background-color: rgba($color: #000000, $alpha: 0.03);
            border-radius: 25%;
            height: 100px;
            width: 100px;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease;
            &:hover {
              height: 110px;
              width: 110px;
              img {
                opacity: 0.3;
                height: 110px;
                width: 110px;
              }
              span {
                font-size: larger;
              }
            }
            img {
              height: 100px;
              width: 100px;
              position: absolute;
              opacity: 0.2;
              border-radius: 25%;

              transition: all 0.3s ease;
            }
            span {
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
  .discography {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        display: flex;
        .card {
          background-color: rgba($color: #000000, $alpha: 0.03);
          border-radius: 25%;
          height: 100px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          &:hover {
            height: 110px;
            width: 110px;
            img {
              opacity: 0.3;
              height: 110px;
              width: 110px;
            }
            span {
              font-size: larger;
            }
          }
          a {
            text-align: center;
            z-index: 1;
            span {
              transition: all 0.3s ease-in-out;
            }
          }
          img {
            height: 100px;
            width: 100px;
            position: absolute;
            opacity: 0.2;
            border-radius: 25%;
            padding: 0;
            transition: all 0.3s ease;
            z-index: -1;
          }
        }
      }
    }
  }
}
