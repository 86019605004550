@import '../styles/global.scss';

.App {
  transition: all 0.25s ease-in-out;

  &.core {
    @include theme($core);
  }
  &.earth {
    @include theme($earth);
  }
  &.mars {
    @include theme($mars);
  }
  &.pluto {
    @include theme($pluto);
  }
  overflow: hidden;
  .screen {
    height: 100vh;

    .main {
      height: 100vh;
      justify-content: center;
      align-items: center;
      position: absolute;
      scroll-snap-type: y mandatory;
      scroll-behavior: smooth;
      overflow: scroll;
      z-index: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      > * {
        height: 100vh;
        width: 100vw;
        scroll-snap-align: start;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
