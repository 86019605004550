@import '../styles/global.scss';

.contact {
  @include landscape {
      flex-direction: row !important;
      .socials{
          width: 50vw !important;
          &_list{
              width: 50vw !important;
          }
      }
  }
  &_body {
      display: flex;
      flex-direction: column;
      align-items: center;
    .emails {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .socials {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      padding: 10px;
    }
    &_list {
      width: 85vw;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      justify-content: space-evenly;

      &_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        background-color: rgba($color: #000000, $alpha: 0.25);
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        a {
          code {
            transition: all 0.2s ease-in-out;
          }
        }
        &:hover {
          height: 120px;
          width: 120px;
          background-color: rgba($color: #000000, $alpha: 0.3);
          a {
            code {
              font-size: larger;
            }
          }
        }
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
