@import './global.scss';

.timeline {
  @include landscape {
      flex-direction: row !important;
      .card{
          width: 40vw !important;
      }
  }
  h3{
    padding: 10px;
  }
  .currentRoles {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .card {
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 80vw;
    height: 80px;
    transition: all 0.25s ease-in-out;
    border-radius: 5px;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
    li {
      padding: 10px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
    }
    a {
      width: 100%;
      
    }
  }
}
