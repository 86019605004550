@import '../styles/global.scss';

.about {
  @include landscape {
    justify-content: space-around !important;
    flex-direction: row !important;
    &_header {
      .description {
        width: 30vw !important;
      }
    }
    &_skills {
      justify-content: flex-end;
      .list {
        width: 35vw !important;
        flex-wrap: wrap;
        .card {
          height: 80px !important;
          width: 80px !important;
          span {
            display: none;
          }
        }
      }
    }
    &_tools {
      justify-content: flex-end;
      .list {
        width: 35vw !important;
        flex-wrap: wrap;
        .card {
          height: 80px !important;
          width: 80px !important;
          code {
            display: none;
          }
        }
      }
    }
    > * {
      width: 35vw;
    }
  }
  @include landscapesmallmobile {
    justify-content: space-around !important;
    flex-direction: row !important;
    &_header {
      .description {
        width: 30vw !important;
        height: 50vh;
        font-size: 2.8vmin;
      }
    }
    &_skills {
      justify-content: flex-end;
      .list {
        width: 30vw !important;
        flex-wrap: wrap !important;
        .card {
          height: 53px !important;
          width: 53px !important;
          font-size: 2vmin;
          span {
            display: none;
          }
        }
      }
    }
    &_tools {
      justify-content: flex-end;
      .list {
        width: 35vw !important;
        flex-wrap: wrap !important;
        .card {
          height: 53px !important;
          width: 53px !important;
          code {
            display: none;
          }
        }
      }
    }
    > * {
      width: 30vw;
    }
  }
  @include mobile {
    .about {
      &_header {
        width: 100%;
        .description {
          width: 90%;
        }
      }
      &_skills {
        .list {
          .card {
            height: 100px;
          }
        }
      }
      &_tools {
        .list {
          .card {
            height: 100px;
          }
        }
      }
    }
  }
  @include smallmobile {
    h3 {
      font-size: 20px;
    }
    font-size: smaller;
    .about {
      &_header {
        width: 100%;
        .description {
          width: 90%;
        }
      }
      &_skills {
        .list {
          .card {
            height: 80px;
            width: 60px;
            &:hover {
              width: 70px;
            }
          }
        }
      }
      &_tools {
        .list {
          .card {
            font-size: smaller;
            height: 80px;
            width: 60px;
            &:hover {
              width: 70px;
            }
          }
        }
      }
    }
  }
  &_header {
    h3 {
      padding: 5px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .description {
      text-align: center;
      width: 60vw;
      padding: 20px;
      background-color: rgba($color: #000000, $alpha: 0.02);
      border-radius: 10px;
    }
  }
  &_skills {
    h3 {
      padding: 5px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 20px;
      width: 80vw;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mobile {
        overflow-x: scroll;
        flex-wrap: nowrap;
      }
      .card {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 120px;
        width: 100px;
        background-color: rgba($color: #000000, $alpha: 0.02);
        opacity: 1;
        z-index: 0;
        border-radius: 20%;
        padding: 10px;
        transition: all 0.2s ease-in-out;

        a {
          text-decoration: none;
        }

        svg {
          width: 35px;

          path {
            display: flex;
          }
        }
      }
    }
  }
  &_tools {
    h3 {
      padding: 5px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 20px;
      width: 80vw;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mobile {
        overflow-x: scroll;
        flex-wrap: nowrap;
      }
      .card {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 120px;
        width: 100px;
        background-color: rgba($color: #000000, $alpha: 0.02);
        opacity: 1;
        z-index: 0;
        border-radius: 20%;
        padding: 10px;
        transition: all 0.2s ease-in-out;
        &:hover {
          
          background-color: rgba($color: #000000, $alpha: 0.04);
        }
        a {
          text-decoration: none;
        }

        svg {
          width: 35px;

          path {
            display: flex;
          }
        }
      }
    }
  }
}
