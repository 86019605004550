@import './global.scss';

.menu {
  display: flex;
  height: 80px;
  width: -webkit-fill-available;
  position: absolute;
  left: 0;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
    padding-right: 10px;

  background-color: rgba($color: #000000, $alpha: 0.05);
  z-index: 3;
  transition: all 350ms linear;

  &.not-top {
    background-color: transparent;
    .brand {
      a {
        .name {
          display: none;
        }
      }
    }
    .clockContainer {
      display: none;
    }
  }

  @include mobile {
    .list {
      display: none !important;
    }
    .brand {
      a {
        .name {
          display: none;
        }
      }
    }
    .icon {
      display: block !important;
    }
  }
  @include landscape {
    .list {
      display: block;
    }
    .icon {
      display: none;
    }
  }

  .brand {
    a {
      z-index: 5;
      img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        margin-right: 15px;
        transition: all 0.15s ease-in-out;
      }
      cursor: pointer;
      text-decoration: none;
      font-size: 2vmin;
    }
  }
  .themesContainer {
    height: 80px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    display: flex;
    transition: all 0.25s ease-in-out;
    z-index: 3;
    code {
      z-index: 5;
      cursor: pointer;
    }
    > * {
      padding: 10px;
    }
    .themes {
      position: relative;
      flex-direction: column;
      display: none;
      list-style: none;
      padding: 0;

      transition: all 0.25s ease-in-out;
      li {
        cursor: pointer;
        padding: 10px;
        border-radius: 20px;
        background-color: rgba($color: #000000, $alpha: 0.25);
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }
      &.active {
        display: block;
      }
    }
  }
  .icon {
    cursor: pointer;
    display: none;
    z-index: 3;
  }
  .list {
    display: none;
    list-style: none;
    z-index: inherit;
    padding: 0;
    margin: 0;
    display: flex;
    li {
      padding: 10px;
    }
  }
}
