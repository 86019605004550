$earth: rgb(241, 241, 241);
$core: rgb(56, 56, 56);
$mars: rgb(182, 106, 35);
$pluto: rgb(172, 217, 255);
$green: rgb(38, 141, 46);
$darkgreen: rgb(23, 88, 29);
$red: rgb(190, 81, 81);
$purple: rgb(124, 0, 124);
$blue: rgb(50, 66, 214);

@function accent-text($theme) {
  @if ($theme==$earth) {
    @return $green;
  }
  @if ($theme==$core) {
    @return $red;
  }
  @if ($theme==$pluto) {
    @return $blue;
  } @else {
    @return $purple;
  }
}
@function icon-fill($theme) {
  @if ($theme==$earth) {
    @return $green;
  }
  @if ($theme==$core) {
    @return $red;
  }
  @if ($theme==$pluto) {
    @return $blue;
  } @else {
    @return $purple;
  }
}

@function text-color($theme) {
  @if ($theme==$earth) {
    @return $core;
  }
  @if ($theme==$core) {
    @return $earth;
  }
  @if ($theme==$pluto) {
    @return $earth;
  } @else {
    @return $darkgreen;
  }
}
@function shadow-color($theme) {
  @if ($theme==$earth) {
    @return $green;
  }
  @if ($theme==$core) {
    @return $red;
  }
  @if ($theme==$pluto) {
    @return $blue;
  } @else {
    @return $darkgreen;
  }
}

@mixin theme($theme) {
  fill: icon-fill($theme);
  color: text-color($theme);
  background-color: $theme;
  .pic {
    filter: drop-shadow(0 0 20px shadow-color($theme));
  }
  span {
    color: accent-text($theme);
    text-shadow: 2px 2px 8px shadow-color($theme);
  }
  .card {
    &:hover {
      box-shadow: 2px 2px 5px shadow-color($theme);
    }
  }
  a {
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    &:hover {
      text-shadow: 2px 2px 5px shadow-color($theme);
      animation: floating 4s ease-in-out infinite;
    }
  }
  .brand {
    a {
      img {
        &:hover {
          filter: drop-shadow(0 0 10px shadow-color($theme));
        }
      }
    }
  }
}

@mixin smallmobile {
  @media (max-width: 380px) and (max-height: 670px) {
    @content;
  }
}
@mixin landscapesmallmobile {
  @media (max-width: 670px) and (max-height: 380px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 668px) {
    @content;
  }
}

@mixin landscape {
  @media (max-height: 505px) {
    @content;
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 500ms ease-in;
  &.appear {
    opacity: 1;
  }
}

.bottom-slide-in {
  transform: translateY(50px);
  opacity: 0;
  transition: opacity 500ms ease-in;
  &.appear{
    transform: translateY(0);
    opacity: 1;
  }
}


