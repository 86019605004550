@import '../styles/global.scss';

.pages {
  &.core {
    @include theme($core);
  }
  &.earth {
    @include theme($earth);
  }
  &.mars {
    @include theme($mars);
  }
  &.pluto {
    @include theme($pluto);
  }
  height: 100vh;
  width: 100vw;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1 !important;
    z-index: 2 !important;
  }

  ul {
    overflow: hidden;
    list-style: none;
    li {
      a {
        text-decoration: none;
      }
    }
  }
}
