@import '../styles/global.scss';

.ventures {
  @include landscape {
    flex-direction: row !important;
    .list {
      display: flex;
      .card {
        width: 16vw !important;
        height: 50vh !important;
        &:hover {
          width: 18vw !important;
          .bg {
            width: 18vw !important;
          }
          li {
            a {
              .logo {
                width: 15vw !important;
              }
            }
          }
        }
        .bg {
          height: 50vh !important;
          width: 20vw !important;
        }
        li {
          a {
            .logo {
              height: 100% !important;
              width: 12vw !important;
            }
          }
        }
      }
    }
  }
  @include landscapesmallmobile {
    flex-direction: row !important;
    .list {
      display: flex;
      .card {
        width: 16vw !important;
        height: 50vh !important;
        &:hover {
          width: 18vw !important;
          .bg {
            width: 18vw !important;
          }
          li {
            a {
              .logo {
                width: 15vw !important;
              }
            }
          }
        }
        .bg {
          height: 50vh !important;
          width: 20vw !important;
        }
        li {
          a {
            .logo {
              height: 100% !important;
              width: 12vw !important;
            }
          }
        }
      }
    }
  }
  @include mobile{
    .list{
      .card{
        
        
        li{
          a{
            .logo{
              height: 60px !important;
            }
          }
        }
        &:hover{
        li{
          a{
            .logo{
              height: 75px !important;
            }
          }
        }
        }
      }
    }
  }
  h3 {
    padding: 10px;
  }
  .list {
    padding: 0px;
    margin: 0px;
    .card {
      width: 100vw;
      height: 12vh;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #000000, $alpha: 0.05);
      border-radius: 15px;
      transition: all 0.2s ease-in-out;
      overflow: hidden;

      .bg {
        border-radius: 15px;
        width: 100vw;
        height: 12vh;
        object-fit: cover;
        opacity: 0;
        position: absolute;
        z-index: 0;
        transition: all 0.3s ease;
      }
      li {
        z-index: 1;
        position: relative;
        a {
          .logo {
            height: 100px;
            position: inherit;
            opacity: 1;
            transition: all 0.3s ease;
          }
        }
      }
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
        
        .bg {
          
          opacity: 0.2;
        }
        li {
          a {
            .logo {
              height: 130px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
