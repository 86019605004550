body,h1,h2,h3,h4,h5,h6 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'Courier New',
    monospace;
}
