@import './global.scss';

.footer {
  text-align: center;
  height: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba($color: #000000, $alpha: 0.05);
  @include mobile {
    display: none;
  }
}
