@import '../styles/global.scss';

.home {
  @include smallmobile {
    .pic {
      height: 80px !important;
      width: 80px !important;
    }
  }
  @include landscape {
    .pic {
      height: 100px !important;
      width: 100px !important;
    }
  }
  .idContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      padding: 20px;
    }
    .id {
      display: flex;
      flex-direction: column;
      align-items: center;
      > * {
        padding: 10px;
      }
      .pic {
        animation: float 6s ease-in-out infinite;
        padding: 0;
        height: 150px;
        width: 150px;
        border-radius: 50%;
      }
      h3 {
        text-align: center;
      }
    }
  }

  .titles {
    display: flex;
    .Typewriter {
      padding-left: 10px;
      &__wrapper {
        background-color: rgb(221, 221, 221);
      }
      &__cursor {
        color: rgb(95, 95, 95);
      }
    }
  }
}
